import { format as formatUtil, toString as toStringUtil } from './format';
import { settings } from '../areas/main/config';

const minute = 60 * 1000;
const hour = 60 * minute;
const day = 24 * hour;

export function tier() {
	return 'client';
}

export function log(...args: any[]) {
	console.log(...args);
}

export function now(offset?: number) {
	let date = serverDate();

	if (offset) {
		offsetTime(date, offset * day);
	}

	return date;
}

export function today(offset?: number) {
	let date = now(offset);

	date.setHours(0, 0, 0, 0);

	return date;
}

export function weekStart(offset?: number) {
	let date = today();

	offsetTime(date, -(date.getDay() * day));

	if (offset) {
		offsetTime(date, offset * 7 * day);
	}

	return date;
}

export function weekEnd(offset?: number) {
	let date = weekStart(offset);

	date.setDate(date.getDate() + 6);

	return date;
}

export function monthStart(offset?: number) {
	let date = today();

	if (offset) {
		date.setMonth(date.getMonth() + offset);
	}

	return new Date(date.getFullYear(), date.getMonth(), 1);
}

export function monthEnd(offset?: number) {
	offset = (offset || 0) + 1;
	let date = monthStart(offset);

	date.setDate(date.getDate() - 1);

	return date;
}

export function yearStart(offset?: number) {
	let date = new Date(today().getFullYear(), 0, 1);

	if (offset) {
		date.setFullYear(date.getFullYear() + offset);
	}

	return date;
}

export function yearEnd(offset?: number) {
	offset = (offset || 0) + 1;
	let date = yearStart(offset);

	date.setDate(date.getDate() - 1);

	return date;
}

export function currentUser() {
	return settings.currentUser;
}

export function session() {
	return settings.session;
}

export function clientIPLog() {
	return settings.clientIPLog;
}

export function format(format: string, ...args: any[]) {
	return formatUtil(format, ...args);
}

export function toString(value: any, format: string) {
	return toStringUtil(value, format);
}

export function repeat(value: any, count: number) {
	return ('' + value).repeat(count);
}

function serverDate() {
	let date = new Date();

	if (settings.clientTimeOffset !== 0) {
		offsetTime(date, settings.clientTimeOffset * -1);
	}

	return date;
}

function offsetTime(date: Date, offset: number) {
	let timezoneOffset = date.getTimezoneOffset();

	date.setTime(date.getTime() + offset);

	//if the timezone offset has changed after adjusting the time,
	//then the time needs to be adjusted by the difference
	let timezoneChange = timezoneOffset - date.getTimezoneOffset();

	if (timezoneChange !== 0) {
		date.setTime(date.getTime() - (timezoneChange * minute));
	}
}
