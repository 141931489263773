import _ from 'lodash';
import { settings } from '../areas/main/config';
import * as fn from './fn';
import { parseBoolean } from './parse';

export function toString(value, format: string) {
	return kendo.toString(value, format);
}

export function isEmptyWhenZeroFormat(format: string): boolean {
	return /^#[#,.]*$/.test(format);
}

export function format(format: string, ...args: any[]) : string {
	function getArg(index: number) {
		if (index >= args.length) throw "There is no argument at position " + index;
		else return args[index];
	}

	if (_.isString(format)) {
		return format.replace(/\{\{|\}\}|\{(\d+)(?::([^}]+?))?\}/g, function (match, index, formatArg) {
			if (match == "{{") return "{";
			if (match == "}}") return "}";

			var arg = getArg(parseInt(index));

			if (formatArg) {
				return toString(arg, formatArg);
			}
			else {
				return arg;
			}
		});
	}
	else {
		return "";
	}
}

export function formatYesNo(value: any): any {
	var v = parseBoolean(value);
	if (v) {
		return settings.strings.yes;
	}
	else if (v === false) {
		return settings.strings.no;
	}

	return '';
}

export function timeSince(date: Date) {
	var seconds = Math.floor((+fn.now() - +date) / 1000);

	var interval = Math.floor(seconds / 31536000);
	if (interval > 1) {
		return format(settings.strings.yearsAgo, interval);
	}

	interval = Math.floor(seconds / 2592000);
	if (interval > 1) {
		return format(settings.strings.monthsAgo, interval);
	}

	interval = Math.floor(seconds / 86400);
	if (interval > 1) {
		return format(settings.strings.daysAgo, interval);
	}

	interval = Math.floor(seconds / 3600);
	if (interval > 1) {
		return format(settings.strings.hoursAgo, interval);
	}

	interval = Math.floor(seconds / 60);
	if (interval > 1) {
		return format(settings.strings.minutesAgo, interval);
	}

	return settings.strings.aMomentAgo;
}
