export default function onFrameDOMContentLoaded(iFrame: HTMLIFrameElement, handler: (this: HTMLDocument) => void) {
	//based on this SO answer: https://stackoverflow.com/a/24603642/182328
	let timer: number;
	let fired = false;

	function onReady(this: HTMLDocument) {
		if (!fired) {
			fired = true;
			clearTimeout(timer);
			handler.call(this);
		}
	}

	function checkLoaded() {
		let document = iFrame.contentDocument;

		//the unwanted initial document for iframes will have a URL that starts
		//with 'about:', if it does, wait for a while and try again..
		if (document && document.URL.indexOf("about:") !== 0) {
			if (document.readyState === "complete") {
				onReady.call(document);
			}
			else {
				document.addEventListener("DOMContentLoaded", onReady);
			}
		}
		else {
			timer = window.setTimeout(checkLoaded, 1);
		}
	}

	checkLoaded();
}
