import appUrl from '../../util/appUrl';
import iconUrl from './icon.png';
import { settings } from '../../areas/main/config';
import './style.less';
import 'noty/lib/noty.css';

const theme: Noty.Theme = 'bootstrap-v3';
const defaultType: Noty.Type = 'info';
const defaultTimeout = 4000;

export default function notify(message: string, title?: string, link?: string, tag?: any) {
	if (!('Notification' in window) || Notification['permission'] === 'denied') {
		showNoty(message, title, link);
	}
	else if (Notification['permission'] === 'granted') {
		showNotification(message, title, link, tag);
	}
	else {
		Notification.requestPermission(function (permission) {
			if (permission === 'granted') {
				showNotification(message, title, link, tag);
			}
			else {
				showNoty(message, title, link, defaultType, false);
			}
		});
	}
}

export function info(message: string, title?: string) {
	return showNoty(message, title, null, 'info');
}

export function warning(message: string, title?: string) {
	return showNoty(message, title, null, 'warning');
}

export function success(message: string, title?: string) {
	return showNoty(message, title, null, 'success');
}

export function error(message: string, title?: string) {
	return showNoty(message, title, null, 'error', false);
}

export async function confirm(prompt: string, yesLabel?: string, noLabel?: string): Promise<boolean> {
	const { default: Noty } = await import('noty');

	return new Promise<boolean>(resolve => {
		const n = new Noty({
			text: prompt,
			layout: 'center',
			modal: true,
			type: 'alert',
			closeWith: [],
			theme: theme,
			animation: {
				open: null,
				close: null
			},
			buttons: [
				Noty.button(yesLabel || settings.strings.yes, 'btn btn-success yes', () => {
					n.close();
					resolve(true);
				}),
				Noty.button(noLabel || settings.strings.no, 'btn btn-default no', () => {
					n.close();
					resolve(false);
				})
			]
		});

		n.show();
	});
}

function showNotification(message: string, title?: string, link?: string, tag?: any) {
	var options = {
		body: message,
		tag: tag,
		icon: appUrl(iconUrl)
	}

	var notification = new Notification(title, options);

	if (link) {
		notification.onclick = function (event) {
			event.preventDefault();
			window.open(link, '_blank');
		}
	}
}

async function showNoty(message: string, title: string, href: string, type: Noty.Type = defaultType, timeout: number | false = defaultTimeout) {
	const { default: Noty } = await import('noty');

	const link = (text: string) => href
		? `<a href="${href}" target="_blank">${text}</a>`
		: text;

	const html = title
		? `<strong>${title}</strong>${link(message)}`
		: link(message);

	const n = new Noty({
		layout: 'topCenter',
		type: type,
		theme: theme,
		text: html,
		timeout: timeout,
		progressBar: false
	});

	n.show();
}
