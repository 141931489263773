import $ from 'jquery';
import appUrl from '../../util/appUrl';
import onFrameDOMContentLoaded from './onFrameDOMContentLoaded';
import { Popup, popupKey, stack as popupStack } from './popup';

export const showModalPageKey = 'konfig_showModalPage';
const zIndexStartsAt = 1050;

export interface ModalDialogOptions {
	callback?: Function;
	hideCloseButton?: boolean;
	maxHeight?: number;
	maxWidth?: number;
}

export interface ModalPageOptions extends ModalDialogOptions {
	url: string;
	title?: string;
};

export function showModalPage(options: ModalPageOptions): Popup {
	if (window.self !== window.top) {
		let f = window.top[showModalPageKey] as Function;
		return f.call(window.top, options);
	}

	var url = options && options.url;
	var title = options && options.title || null;
	var withTitle = title === null ? '' : 'with-title';

	$(".has-popover").popover("hide");

	let $frame = $("<iframe frameborder='0' width='100%' src='about:blank'></iframe>");
	let frameElement = $frame[0] as HTMLIFrameElement;

	/* this behaviour is bound twice (and should be tolerant of multiple execution):
	*
	*  - the DOMContentLoaded event is bound on the loading document to try set up the modal
	*    styling as early as possible and avoid any flashes of incorrectly styled layout
	*
	*  - the iframe load event is bound to handle any navigation that occurs inside the
	*    frame (and apply modal styling to the subsequent document). Since popup navigation
	*    is pretty rare, and there are no easy workarounds, the flash of unstyled content
	*    is acceptable after navigation.
	*/
	function setModalBodyClass(this: HTMLDocument) {
		let classes = this.body.classList;

		classes.add('modal-frame');

		if (withTitle) {
			classes.add(withTitle);
		}
	}

	onFrameDOMContentLoaded(frameElement, setModalBodyClass);

	frameElement.addEventListener('load', function () {
		setModalBodyClass.call(this.contentDocument)
	});

	if (url.toLowerCase().indexOf("chrome=") < 0) {
		url = url + (url.indexOf("?") < 0 ? "?" : "&") + "chrome=false";
	}

	$frame.attr("src", appUrl(url));

	var element = $($('#modal-window-template').text())
		.addClass(withTitle)
		.prependTo("body");

	var contentSection = element.find('.modal-content > section');

	$frame.appendTo(contentSection);

	if (title !== null) {
		$(`<header class="main"><label>${title}</label></header>`).insertBefore(contentSection);
	}

	return showElement(element, options);
}

export function dismissModal() {
	let currentPopup = popupStack.peek();

	if (currentPopup) {
		currentPopup.close();
	}
}

function showElement(element: any, options: ModalDialogOptions): Popup {
	var popup = new Popup();
	popup.callbackHandler = options.callback;
	popup.element = element

	popupStack.push(popup);

	let iframe = element.find("iframe");

	if (iframe.length) {
		//attach the popup to the iframe if there is one
		iframe[0][popupKey] = popup;
	}

	element
		.modal({
			keyboard: !options.hideCloseButton,
			backdrop: false,
			animate: false,
			show: false
		})
		.on('show.bs.modal', function () {
			if (options.hideCloseButton) {
				element.find(".modal-footer").hide();
			}

			if (options.maxHeight) {
				element.find('.modal-dialog').css('max-height', options.maxHeight);
			}

			if (options.maxWidth) {
				element.find('.modal-dialog').css('max-width', options.maxWidth);
			}
		})
		.on('shown.bs.modal', function () {
			var depth = $(".modal.in").length - 1;

			//nudge the position of nested popup windows to make
			//their overlap clearer, like the 'cascade' layout in
			//desktop window managers; to avoid cascading off the
			//page, reset the offset regularly
			element.addClass(`offset-${depth % 5}`);

			element.css({
				"z-index": zIndexStartsAt + depth * 10,
				"padding-right": 0
			});

			iframe.focus();
		})
		.on('hidden.bs.modal', function () {
			popup.callback();
			$(this).data('modal', null).remove();
			popupStack.pop();
		})
		.modal('show');

	return popup;
}
