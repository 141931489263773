export const popupKey = 'konfig_Popup';

export class Popup {
	callbackHandler: Function;
	callbackContext;
	callbackArgument;

	element: JQuery;
	owner: Window;

	close() {
		if (this.element) {
			this.element.modal("hide");
		}
	}

	callback(): any {
		if (this.callbackHandler) {
			return this.callbackHandler(this.callbackArgument, this.callbackContext);
		}
	}
}

export function findFramePopup(frameWindow: Window): Popup {
	let frame = frameWindow.frameElement
	if (frame) {
		if (frame[popupKey]) {
			return frame[popupKey];
		}
	}
}

export function findPopup(owner: Window): Popup {
	let windowPopup = stack.find(owner);

	if (windowPopup) {
		return windowPopup;
	}

	return findFramePopup(owner);
}

export function getCurrentPopup(): Popup {
	return stack.peek();
}

export function getInputArgument(): any {
	var p = findPopup(window.self);
	if (!p) {
		return null;
	}

	p.callbackArgument = null;
	return p.callback();
}

export function closePopupOrSelf() {
	var p = findPopup(window);
	if (p) {
		p.close();
	}
	else {
		self.close();
	}
}

export namespace stack {
	const key = 'konfig_popupStack';

	export function peek(): Popup | null {
		let stack = requireStack();
		return stack.length > 0 ? stack[stack.length - 1] : null;
	}

	export function pop(): Popup | null {
		let stack = requireStack();
		return stack.length > 0 ? stack.pop() : null;
	}

	export function push(value: Popup) {
		value.owner = window.self;
		requireStack().push(value);
	}

	export function find(owner: Window): Popup | null {
		for (let x of requireStack()) {
			if (x.owner === owner) {
				return x;
			}
		}

		return null;
	}

	function requireStack() {
		let result = window.top[key] as Popup[];

		if (!result) {
			result = [];
			window.top[key] = result;
		}

		return result;
	}
}
