import Cookie, { CookieAttributes } from 'js-cookie';

export function get(name: string): string | undefined {
	return Cookie.get(name);
}

export function set(name: string, value: string, options?: CookieAttributes): void {
	Cookie.set(name, value, requireStrict(options));
}

export function remove(name: string, options?: CookieAttributes): void {
	Cookie.remove(name, requireStrict(options));
}

function requireStrict(options?: CookieAttributes): CookieAttributes {
	options = options || {};
	options.sameSite = 'strict';

	return options;
}
