import jQuery from 'jquery';
import _ from 'lodash';

export function parseJson<T>(value: string): T {
	if (!value) return null;
	else return <T>jQuery.parseJSON(value);
}

export function parseClientModel(element: JQuery) {
	var content = element.html();

	if (content) {
		return parseJson(content.replace(/<\/\//g, '</'));
	}
	else {
		return null;
	}
}

export function parseBoolean(value: any): boolean {
	if (_.isBoolean(value)) {
		return value;
	}
	else if (_.isString(value)) {
		switch (value.toLowerCase()) {
			case 'true':
			case '1':
				return true;

			case 'false':
			case '0':
				return false;
		}
	}

	return null;
}

export function parseDate(value: string, format?: string) {
	return kendo.parseDate(value, format);
}

export function parseIso8601Date(value: string): Date {
	const pattern = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)(?:Z|(\+|-)([\d|:]*))?$/;

	if (pattern.test(value)) {
		var result = new Date(value);

		if (!isNaN(result.valueOf())) {
			return result;
		}
	}

	return null;
}
